<template>
  <v-form ref="form" lazy-validation>
    <v-card outlined>
      <v-card-title class="card-title-actions">
        <v-spacer />
        <vx-btn
          v-if="!editAll && $route.params.id"
          width="120"
          outlined
          color="light"
          @click="$edit('collection-schema', schema._id)"
        >
          Edit Schema
        </vx-btn>
        <vx-btn width="100" outlined color="light" class="ml-2" @click="$previous()">Cancel</vx-btn>
        <vx-btn width="100" outlined color="light" class="ml-2" :loading="loading" @click="$validate(save)">
          Save
        </vx-btn>
        <vx-btn outlined color="light" class="ml-2" :loading="loading" @click="$validate(save, 'previous')">
          Save & close
        </vx-btn>
        <vx-btn v-if="!editAll" outlined color="light" class="ml-2" :loading="loading" @click="$validate(save, 'next')">
          Save & next
        </vx-btn>
      </v-card-title>

      <v-card-text v-if="!editAll" class="mt-8">
        <v-row>
          <v-col cols="12" sm="6" class="py-0">
            <vx-input
              v-model="collection.name"
              type="text"
              name="name"
              label="Name"
              required
              @change="collection.setSlug(collection.slug || collection.name)"
            />
          </v-col>

          <v-col cols="12" sm="6" class="py-0">
            <vx-input
              v-model="collection.slug"
              type="text"
              name="slug"
              label="Slug"
              required
              :disabled="$route.params.id && !dev"
              @change="collection.setSlug(collection.slug)"
            />
          </v-col>

          <v-col cols="12" sm="6" class="py-0">
            <vx-input
              v-model="collection.description"
              type="textarea"
              name="description"
              label="Description"
              counter="280"
              max="280"
            />
          </v-col>

          <v-col cols="12" sm="6" class="py-0">
            <vx-input v-model="collection.publishedAt" type="date" name="publishedAt" label="Publish date" />
          </v-col>
        </v-row>
      </v-card-text>

      <v-divider class="mb-8" />

      <v-card-text>
        <v-row>
          <v-col v-for="(field, i) in schema.fields" :key="i" cols="12" sm="6" class="py-0">
            <template v-if="field.type === 'radio' && field.slug == 'venue_type'">
              <label>{{ field.name }} </label>
              <v-radio-group
                v-model="collection.fields[field.slug]"
                row
                class="py-0"
                @change="onChangeVenueType(field)"
              >
                <v-radio v-for="(item, index) in VENUE_TYPES" :key="index" :label="item['text']" :value="item['value']">
                </v-radio>
              </v-radio-group>
            </template>
            <div v-else-if="field.type === 'venues'">
              <template v-if="venueType === VENUE_TYPE.LOCATION">
                <vx-input
                  v-model="collection.fields[field.slug]"
                  type="autocomplete"
                  :items="location.data"
                  item-text="name"
                  item-value="_id"
                  :name="field.name"
                  :multiple="field.multiple"
                  :label="field.name"
                  :required="!editAll && field.validation.required && collection._isActive"
                  :loading="getVenuesLoading"
                  :search-input.sync="locationPageSearch"
                  @change="selectItem(field), key.list++"
                >
                  <template v-slot:append-item>
                    <div
                      v-if="location.data.length < location.count"
                      v-intersect="loadMoreLocations"
                      class="pa-4 teal--text"
                    >
                      <span v-if="location.loading">Loading more items ...</span>
                    </div>
                  </template>
                </vx-input>
              </template>
              <template v-else>
                <vx-input
                  v-model="collection.fields[field.slug]"
                  type="autocomplete"
                  :items="space.data"
                  item-text="name"
                  item-value="_id"
                  :name="field.name"
                  :multiple="field.multiple"
                  :label="field.name"
                  :required="!editAll && field.validation.required && collection._isActive"
                  :loading="getVenuesLoading"
                  :search-input.sync="spacePageSearch"
                  @change="selectItem(field)"
                >
                  <template v-slot:append-item>
                    <div v-if="space.data.length < space.count" v-intersect="loadMoreSpaces" class="pa-4 teal--text">
                      <span v-if="space.loading">Loading more items ...</span>
                    </div>
                  </template>
                </vx-input>
              </template>
            </div>
            <template v-else-if="field.type === 'collection'">
              <vx-input
                v-model="collection.fields[field.slug]"
                type="autocomplete"
                :items="field.values"
                item-text="name"
                item-value="_id"
                :name="field.name"
                :multiple="field.multiple"
                :label="field.name"
                :required="!editAll && field.validation.required && collection._isActive"
                append-outer-icon="mdi-plus"
                @click:append-outer="$openBlankWindow($toAdd('collection', { id: field.schemaId }))"
                @change="selectItem(field)"
              />
              <vx-list
                v-if="field.multiple && Array.isArray(collection.fields[field.slug]) && field.values?.length"
                :key="i + key.list"
                class="mb-7 rounded py-0"
                :list-props="{ dense: true, outlined: true }"
                draggable
                :items="collection.fields[field.slug]"
                :item-title="item => field.values?.find(value => value._id === item).name"
                @edit="item => $openBlankWindow($toEdit('collection', item))"
              />
            </template>
            <vx-input
              v-else-if="SPECIAL_FIELD_TYPES.includes(field.type)"
              v-model="collection.fields[field.slug]"
              type="autocomplete"
              :items="field.values"
              item-text="name"
              item-value="_id"
              :name="field.name"
              :label="field.name"
              :multiple="field.multiple"
              :required="field.validation.required"
              @change="selectItem(field)"
            />
            <template v-else-if="field.type === 'file'">
              <vx-input
                v-model="collection.fields[field.slug]"
                type="text"
                :name="field.name"
                :label="field.name"
                :required="field.validation.required"
                append-outer-icon="mdi-folder-multiple-image"
                @click:append-outer="openFileDialog(field)"
              />
              <v-row>
                <v-col cols="12" lg="6">
                  <vx-img
                    rectangle
                    preview
                    placeholder
                    class="w-100 w-md-50 border-primary-1 rounded mb-4 w-md-100"
                    :src="collection.fields[field.slug]"
                  />
                </v-col>
                <v-col cols="12" lg="6">
                  <vx-input v-model="collection.fields[`${field.slug}_alt`]" :type="'text'" name="alt" label="alt" />
                </v-col>
              </v-row>
            </template>

            <template v-else-if="field.type === 'iframely'">
              <ContentUrlField
                :content="content"
                @hook:mounted="setData(field.slug)"
                @setIframelyData="setIframelyData($event, field.slug)"
              ></ContentUrlField>
            </template>

            <vx-input
              v-else
              v-model="collection.fields[field.slug]"
              class="py-0"
              :type="field.type"
              :name="field.name"
              :label="field.name"
              :items="field.items"
              :multiple="field.multiple"
              :required="field.validation.required"
              chips
            />
          </v-col> </v-row
      ></v-card-text>
    </v-card>

    <AddFile v-model="dialog.file" @resolve="onResolveFile" @reject="dialog.file = false" />
  </v-form>
</template>

<script>
import { Collection, CollectionSchema, Content } from '@tutti/models';
import { FIELD_TYPES, VENUE_TYPE, PRICE_MODEL, VENUE_TYPES, VENUE_STATUS, VENUE_VISIBILITY } from '@tutti/constants';
import { CollectionService, CollectionSchemaService, SpaceService, LocationService } from '@tutti/services';

import AddFile from '@admin/dialogs/add-file';
import ContentUrlField from '@admin/views/content/components/ContentUrlField';
import isNaN from 'lodash/isNaN';

export default {
  name: 'LandingPageEdit',

  components: { AddFile, ContentUrlField },

  props: {
    edit: { type: Boolean, default: false },
    editAll: { type: Boolean, default: false },
  },
  data() {
    return {
      tab: 0,
      SPECIAL_FIELD_TYPES: FIELD_TYPES.filter(field => field.special).map(field => field.value),
      loading: false,
      created: false,
      collection: new Collection(),
      schema: {},
      field: {},
      dialog: {
        file: false,
      },
      content: new Content({
        isVerified: true,
        system: true,
        venueType: VENUE_TYPE.SPACE,
        disabled: this.edit,
      }),
      separator: '/',
      venueType: VENUE_TYPE.SPACE,
      VENUE_TYPES,
      VENUE_TYPE,
      spaces: [],
      getVenuesLoading: false,
      key: {
        list: 0,
      },
      location: {
        data: [],
        page: 1,
        limit: 24,
        loading: false,
        count: 0,
      },
      locationPageSearch: null,
      space: {
        data: [],
        page: 1,
        limit: 24,
        loading: false,
        count: 0,
      },
      spacePageSearch: null,
    };
  },
  watch: {
    async locationPageSearch(val) {
      this.location.page = 1;
      await this.getVenues(this.venueType, val);
    },
    async spacePageSearch(val) {
      this.space.page = 1;
      await this.getVenues(this.venueType, val);
    },
  },
  async created() {
    this.tab = this.$route?.params?.tab || 0;

    await this.getPage();
    await this.getPageSchema();
    this.created = true;
    if (this.collection.fields.venue_type) {
      this.venueType = this.collection.fields.venue_type;
    }
    this.getVenues(this.venueType);
  },

  methods: {
    onChangeVenueType(field) {
      this.venueType = this.collection.fields[field.slug];
      this.clearFields();
      this.getVenues(this.collection.fields[field.slug]);
    },

    async getVenues(venueType, search) {
      this.getVenuesLoading = true;

      const filter = { status: VENUE_STATUS.OPEN, visibility: VENUE_VISIBILITY.PUBLIC };
      if (this.edit && this.collection.fields?.listing_selector && !search) {
        filter._id = this.collection.fields?.listing_selector;
      }

      const payload = {};
      if (search) {
        payload.searching = search;
      }

      if (venueType == VENUE_TYPE.LOCATION) {
        this.location.loading = true;
        payload.limit = this.location.limit;
        payload.page = this.location.page;

        const locations = await LocationService.getLocations({
          ...payload,
          filter: { ...filter },
          select: `name price priceModel space accountId slug prices bookingVenue minimumPrices media path nearestPlace what3Words address`,
        });
        if (locations) {
          this.location.data = [...this.location.data, ...locations.data.data];
          this.location.count = locations.data.count;
          this.location.loading = false;
        }
      }

      if (venueType == VENUE_TYPE.SPACE) {
        this.space.loading = true;
        payload.limit = this.space.limit;
        payload.page = this.space.page;
        const spaces = await SpaceService.getSpaces({
          ...payload,
          filter: { ...filter, 'location.bookingVenue': { $in: [VENUE_TYPE.SPACE, VENUE_TYPE.BOTH] } },
          select: `name price priceModel slug prices minimumPrices media path`,
          populate: {
            locationId: 'name nearestPlace what3Words address',
          },
        });

        if (spaces) {
          this.spaces = spaces.data.data;
          this.space.data = [...this.space.data, ...spaces.data.data];
          this.space.count = spaces.data.count;
          this.space.loading = false;
        }
      }
      this.getVenuesLoading = false;
    },
    openFileDialog(field) {
      this.field = field;
      this.dialog.file = true;
    },

    onResolveFile(file) {
      this.collection.fields[this.field.slug] = file.url;
      this.dialog.file = false;
    },

    removeField(array, index) {
      array.splice(index, 1);
      this.key++;
    },

    async getPage() {
      if (this.$route.params.id) {
        const response = await CollectionService.getByIdAdmin(this.$route.params.id);

        if (response) {
          this.collection = new Collection(response.data);
        }
      }
    },

    async getPageSchema() {
      if (this.$route.query.id || this.collection.schemaId) {
        const response = await CollectionSchemaService.getByIdAdmin(this.$route.query.id || this.collection.schemaId);

        if (response) {
          this.schema = new CollectionSchema(response.data);
          this.schema.setFields(this.collection.fields);

          if (this.editAll) {
            this.$setTitle(`Edit all collections in ${this.schema.name}`);
          } else if (this.edit) {
            this.$setTitle(`Edit ${this.collection.name} in ${this.schema.name}`);
          } else {
            this.$setTitle(`Add collection in ${this.schema.name}`);
          }
        }
      } else {
        this.$previous();
      }
    },

    async save(action) {
      this.loading = true;
      this.collection.schemaId = this.schema._id;
      const response = this.editAll
        ? CollectionService.editAll(this.collection.get())
        : await CollectionService.save(this.collection.get());
      if (response) {
        if (action === 'next') {
          this.collection = new Collection();
          this.$reset();
          this.$setTitle('');
        } else if (action === 'previous') {
          this.$previous();
        } else if (response.data?._id) {
          this.$replace(this.$toEdit('collection', response.data?._id));
        }
      }
      this.loading = false;
    },

    setData(fieldSlug = undefined) {
      if (fieldSlug) {
        if (this.collection.fields[fieldSlug]?.url) {
          this.content = this.collection.fields[fieldSlug];
          this.content.disabled = true;
        }
      }
    },

    setIframelyData(contendData = this.content, fieldSlug = undefined) {
      if (fieldSlug) {
        this.collection.fields[fieldSlug] = contendData;
        this.$set(this.collection, 'name');
        this.collection.name = contendData.meta.title;
        this.collection.slug = contendData.meta.title;
        this.collection.setSlug(this.collection.name);
      }
    },

    selectItem(field) {
      if (this.schema.slug === 'listing-repeater') {
        this.mapListingRepeaterValues(field);
      }
    },
    mapListingRepeaterValues(field) {
      let selectedId = this.collection.fields[field.slug];
      let selectedVenue = [];
      let location = {};
      let media = [];
      if (this.venueType == VENUE_TYPE.SPACE) {
        selectedVenue = this.spaces.find(item => item._id === selectedId);
        location = selectedVenue?.locationId;
        media = selectedVenue?.media;
      } else {
        selectedVenue = this.location.data.find(item => item._id === selectedId);
        location = selectedVenue;
        media = selectedVenue?.media ? selectedVenue?.media : selectedVenue?.space ? selectedVenue?.space?.media : [];
      }

      this.collection.name = selectedVenue ? selectedVenue.name : '';
      this.collection.slug = selectedVenue ? selectedVenue.name : '';
      if (selectedVenue) this.collection.setSlug(this.collection.name);

      if (selectedVenue) {
        this.schema.fields.forEach(element => {
          let key = element.slug;

          if (key == 'listing_name') {
            this.collection.fields[key] = selectedVenue.name;
          }
          if (key == 'listing_url') {
            this.collection.fields[key] = selectedVenue.path;
          }
          if (key == 'listing_price') {
            this.collection.fields[key] = this.getPriceString(selectedVenue);
          }
          if (key == 'listing_nearest_place') {
            this.collection.fields[key] = this.$getNearestPlace(location);
          }
          if (key == 'listing_photo' && media?.length) {
            this.collection.fields[key] = media[0]?.url;
            this.collection.fields[`${key}_alt`] = selectedVenue.name;
          }
        });
      } else {
        this.clearFields();
      }
    },

    clearFields() {
      this.schema.fields.forEach(element => {
        if (element.slug != 'venue_type') {
          this.collection.fields[element.slug] = null;
          if (element.slug == 'listing_photo') this.collection.fields[`${element.slug}_alt`] = null;
        }
      });
    },

    spacePriceFilter(space = {}) {
      if (space.priceModel == PRICE_MODEL.QUOTE) {
        return 'Quote';
      } else if (space.priceModel == PRICE_MODEL.FIXED) {
        return this.priceFilter(space.price.value);
      }
    },

    priceFilter(value, type = null) {
      if (value || value === 0) {
        if (type) {
          return value ? `${this.getPrice(value)}/${type}` : 'N/A';
        } else {
          return this.getPrice(value);
        }
      } else {
        return 'N/A';
      }
    },

    getPrice(value) {
      if (isNaN(Number(value))) {
        return value;
      }
      const options = {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      };

      const _value = value / 100;
      const __value = _value % 1 === 0 ? Number(_value).toLocaleString() : Number(_value).toLocaleString('en', options);

      return `£${__value}`;
    },

    getPriceString(venue) {
      let priceString = '';
      if (venue.bookingVenue === VENUE_TYPE.SPACE) {
        if (this.$hasFiniteValue(venue.space?.price?.min) && this.$hasFiniteValue(venue.space?.price?.max)) {
          priceString = 'Spaces From ';
          if (venue.space?.price?.min === venue.space?.price?.max) {
            priceString += `${this.priceFilter(venue.space?.price?.min)}`;
          } else {
            priceString += `${this.priceFilter(venue.space?.price?.min)} to ${this.priceFilter(
              venue.space?.price?.max
            )}`;
          }
          priceString += ` ${this.separator} ${venue.space?.price?.type || 'hour'}`;
        } else {
          priceString = 'Price on request';
        }
      } else if (venue.price || venue.minimumPrices) {
        if (venue.priceModel == PRICE_MODEL.QUOTE) {
          if (venue.minimumPrices?.length) {
            priceString = `From ${this.priceFilter(venue.minimumPrices[0].value / venue.minimumPrices[0].count)} ${
              this.separator
            } ${venue.minimumPrices[0].type}`;
          } else {
            priceString = 'Price on request';
          }
        } else {
          if (venue.price?.value) {
            if (venue.prices?.length > 1) priceString = 'From ';
            priceString += `${this.spacePriceFilter(venue)} ${this.separator} ${venue.price?.type}`;
          } else {
            priceString = 'Free';
          }
        }
      }
      return priceString;
    },
    loadMoreLocations() {
      this.location.page += 1;
      this.getVenues(this.venueType);
    },
    loadMoreSpaces() {
      this.space.page += 1;
      this.getVenues(this.venueType);
    },
  },
};
</script>
