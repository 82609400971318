var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-form', {
    ref: "form",
    attrs: {
      "lazy-validation": ""
    }
  }, [_c('v-card', {
    attrs: {
      "outlined": ""
    }
  }, [_c('v-card-title', {
    staticClass: "card-title-actions"
  }, [_c('v-spacer'), !_vm.editAll && _vm.$route.params.id ? _c('vx-btn', {
    attrs: {
      "width": "120",
      "outlined": "",
      "color": "light"
    },
    on: {
      "click": function click($event) {
        return _vm.$edit('collection-schema', _vm.schema._id);
      }
    }
  }, [_vm._v(" Edit Schema ")]) : _vm._e(), _c('vx-btn', {
    staticClass: "ml-2",
    attrs: {
      "width": "100",
      "outlined": "",
      "color": "light"
    },
    on: {
      "click": function click($event) {
        return _vm.$previous();
      }
    }
  }, [_vm._v("Cancel")]), _c('vx-btn', {
    staticClass: "ml-2",
    attrs: {
      "width": "100",
      "outlined": "",
      "color": "light",
      "loading": _vm.loading
    },
    on: {
      "click": function click($event) {
        return _vm.$validate(_vm.save);
      }
    }
  }, [_vm._v(" Save ")]), _c('vx-btn', {
    staticClass: "ml-2",
    attrs: {
      "outlined": "",
      "color": "light",
      "loading": _vm.loading
    },
    on: {
      "click": function click($event) {
        return _vm.$validate(_vm.save, 'previous');
      }
    }
  }, [_vm._v(" Save & close ")]), !_vm.editAll ? _c('vx-btn', {
    staticClass: "ml-2",
    attrs: {
      "outlined": "",
      "color": "light",
      "loading": _vm.loading
    },
    on: {
      "click": function click($event) {
        return _vm.$validate(_vm.save, 'next');
      }
    }
  }, [_vm._v(" Save & next ")]) : _vm._e()], 1), !_vm.editAll ? _c('v-card-text', {
    staticClass: "mt-8"
  }, [_c('v-row', [_c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('vx-input', {
    attrs: {
      "type": "text",
      "name": "name",
      "label": "Name",
      "required": ""
    },
    on: {
      "change": function change($event) {
        return _vm.collection.setSlug(_vm.collection.slug || _vm.collection.name);
      }
    },
    model: {
      value: _vm.collection.name,
      callback: function callback($$v) {
        _vm.$set(_vm.collection, "name", $$v);
      },
      expression: "collection.name"
    }
  })], 1), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('vx-input', {
    attrs: {
      "type": "text",
      "name": "slug",
      "label": "Slug",
      "required": "",
      "disabled": _vm.$route.params.id && !_vm.dev
    },
    on: {
      "change": function change($event) {
        return _vm.collection.setSlug(_vm.collection.slug);
      }
    },
    model: {
      value: _vm.collection.slug,
      callback: function callback($$v) {
        _vm.$set(_vm.collection, "slug", $$v);
      },
      expression: "collection.slug"
    }
  })], 1), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('vx-input', {
    attrs: {
      "type": "textarea",
      "name": "description",
      "label": "Description",
      "counter": "280",
      "max": "280"
    },
    model: {
      value: _vm.collection.description,
      callback: function callback($$v) {
        _vm.$set(_vm.collection, "description", $$v);
      },
      expression: "collection.description"
    }
  })], 1), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('vx-input', {
    attrs: {
      "type": "date",
      "name": "publishedAt",
      "label": "Publish date"
    },
    model: {
      value: _vm.collection.publishedAt,
      callback: function callback($$v) {
        _vm.$set(_vm.collection, "publishedAt", $$v);
      },
      expression: "collection.publishedAt"
    }
  })], 1)], 1)], 1) : _vm._e(), _c('v-divider', {
    staticClass: "mb-8"
  }), _c('v-card-text', [_c('v-row', _vm._l(_vm.schema.fields, function (field, i) {
    var _field$values;

    return _c('v-col', {
      key: i,
      staticClass: "py-0",
      attrs: {
        "cols": "12",
        "sm": "6"
      }
    }, [field.type === 'radio' && field.slug == 'venue_type' ? [_c('label', [_vm._v(_vm._s(field.name) + " ")]), _c('v-radio-group', {
      staticClass: "py-0",
      attrs: {
        "row": ""
      },
      on: {
        "change": function change($event) {
          return _vm.onChangeVenueType(field);
        }
      },
      model: {
        value: _vm.collection.fields[field.slug],
        callback: function callback($$v) {
          _vm.$set(_vm.collection.fields, field.slug, $$v);
        },
        expression: "collection.fields[field.slug]"
      }
    }, _vm._l(_vm.VENUE_TYPES, function (item, index) {
      return _c('v-radio', {
        key: index,
        attrs: {
          "label": item['text'],
          "value": item['value']
        }
      });
    }), 1)] : field.type === 'venues' ? _c('div', [_vm.venueType === _vm.VENUE_TYPE.LOCATION ? [_c('vx-input', {
      attrs: {
        "type": "autocomplete",
        "items": _vm.location.data,
        "item-text": "name",
        "item-value": "_id",
        "name": field.name,
        "multiple": field.multiple,
        "label": field.name,
        "required": !_vm.editAll && field.validation.required && _vm.collection._isActive,
        "loading": _vm.getVenuesLoading,
        "search-input": _vm.locationPageSearch
      },
      on: {
        "update:searchInput": function updateSearchInput($event) {
          _vm.locationPageSearch = $event;
        },
        "update:search-input": function updateSearchInput($event) {
          _vm.locationPageSearch = $event;
        },
        "change": function change($event) {
          _vm.selectItem(field), _vm.key.list++;
        }
      },
      scopedSlots: _vm._u([{
        key: "append-item",
        fn: function fn() {
          return [_vm.location.data.length < _vm.location.count ? _c('div', {
            directives: [{
              name: "intersect",
              rawName: "v-intersect",
              value: _vm.loadMoreLocations,
              expression: "loadMoreLocations"
            }],
            staticClass: "pa-4 teal--text"
          }, [_vm.location.loading ? _c('span', [_vm._v("Loading more items ...")]) : _vm._e()]) : _vm._e()];
        },
        proxy: true
      }], null, true),
      model: {
        value: _vm.collection.fields[field.slug],
        callback: function callback($$v) {
          _vm.$set(_vm.collection.fields, field.slug, $$v);
        },
        expression: "collection.fields[field.slug]"
      }
    })] : [_c('vx-input', {
      attrs: {
        "type": "autocomplete",
        "items": _vm.space.data,
        "item-text": "name",
        "item-value": "_id",
        "name": field.name,
        "multiple": field.multiple,
        "label": field.name,
        "required": !_vm.editAll && field.validation.required && _vm.collection._isActive,
        "loading": _vm.getVenuesLoading,
        "search-input": _vm.spacePageSearch
      },
      on: {
        "update:searchInput": function updateSearchInput($event) {
          _vm.spacePageSearch = $event;
        },
        "update:search-input": function updateSearchInput($event) {
          _vm.spacePageSearch = $event;
        },
        "change": function change($event) {
          return _vm.selectItem(field);
        }
      },
      scopedSlots: _vm._u([{
        key: "append-item",
        fn: function fn() {
          return [_vm.space.data.length < _vm.space.count ? _c('div', {
            directives: [{
              name: "intersect",
              rawName: "v-intersect",
              value: _vm.loadMoreSpaces,
              expression: "loadMoreSpaces"
            }],
            staticClass: "pa-4 teal--text"
          }, [_vm.space.loading ? _c('span', [_vm._v("Loading more items ...")]) : _vm._e()]) : _vm._e()];
        },
        proxy: true
      }], null, true),
      model: {
        value: _vm.collection.fields[field.slug],
        callback: function callback($$v) {
          _vm.$set(_vm.collection.fields, field.slug, $$v);
        },
        expression: "collection.fields[field.slug]"
      }
    })]], 2) : field.type === 'collection' ? [_c('vx-input', {
      attrs: {
        "type": "autocomplete",
        "items": field.values,
        "item-text": "name",
        "item-value": "_id",
        "name": field.name,
        "multiple": field.multiple,
        "label": field.name,
        "required": !_vm.editAll && field.validation.required && _vm.collection._isActive,
        "append-outer-icon": "mdi-plus"
      },
      on: {
        "click:append-outer": function clickAppendOuter($event) {
          _vm.$openBlankWindow(_vm.$toAdd('collection', {
            id: field.schemaId
          }));
        },
        "change": function change($event) {
          return _vm.selectItem(field);
        }
      },
      model: {
        value: _vm.collection.fields[field.slug],
        callback: function callback($$v) {
          _vm.$set(_vm.collection.fields, field.slug, $$v);
        },
        expression: "collection.fields[field.slug]"
      }
    }), field.multiple && Array.isArray(_vm.collection.fields[field.slug]) && (_field$values = field.values) !== null && _field$values !== void 0 && _field$values.length ? _c('vx-list', {
      key: i + _vm.key.list,
      staticClass: "mb-7 rounded py-0",
      attrs: {
        "list-props": {
          dense: true,
          outlined: true
        },
        "draggable": "",
        "items": _vm.collection.fields[field.slug],
        "item-title": function itemTitle(item) {
          var _field$values2;

          return (_field$values2 = field.values) === null || _field$values2 === void 0 ? void 0 : _field$values2.find(function (value) {
            return value._id === item;
          }).name;
        }
      },
      on: {
        "edit": function edit(item) {
          return _vm.$openBlankWindow(_vm.$toEdit('collection', item));
        }
      }
    }) : _vm._e()] : _vm.SPECIAL_FIELD_TYPES.includes(field.type) ? _c('vx-input', {
      attrs: {
        "type": "autocomplete",
        "items": field.values,
        "item-text": "name",
        "item-value": "_id",
        "name": field.name,
        "label": field.name,
        "multiple": field.multiple,
        "required": field.validation.required
      },
      on: {
        "change": function change($event) {
          return _vm.selectItem(field);
        }
      },
      model: {
        value: _vm.collection.fields[field.slug],
        callback: function callback($$v) {
          _vm.$set(_vm.collection.fields, field.slug, $$v);
        },
        expression: "collection.fields[field.slug]"
      }
    }) : field.type === 'file' ? [_c('vx-input', {
      attrs: {
        "type": "text",
        "name": field.name,
        "label": field.name,
        "required": field.validation.required,
        "append-outer-icon": "mdi-folder-multiple-image"
      },
      on: {
        "click:append-outer": function clickAppendOuter($event) {
          return _vm.openFileDialog(field);
        }
      },
      model: {
        value: _vm.collection.fields[field.slug],
        callback: function callback($$v) {
          _vm.$set(_vm.collection.fields, field.slug, $$v);
        },
        expression: "collection.fields[field.slug]"
      }
    }), _c('v-row', [_c('v-col', {
      attrs: {
        "cols": "12",
        "lg": "6"
      }
    }, [_c('vx-img', {
      staticClass: "w-100 w-md-50 border-primary-1 rounded mb-4 w-md-100",
      attrs: {
        "rectangle": "",
        "preview": "",
        "placeholder": "",
        "src": _vm.collection.fields[field.slug]
      }
    })], 1), _c('v-col', {
      attrs: {
        "cols": "12",
        "lg": "6"
      }
    }, [_c('vx-input', {
      attrs: {
        "type": 'text',
        "name": "alt",
        "label": "alt"
      },
      model: {
        value: _vm.collection.fields["".concat(field.slug, "_alt")],
        callback: function callback($$v) {
          _vm.$set(_vm.collection.fields, "".concat(field.slug, "_alt"), $$v);
        },
        expression: "collection.fields[`${field.slug}_alt`]"
      }
    })], 1)], 1)] : field.type === 'iframely' ? [_c('ContentUrlField', {
      attrs: {
        "content": _vm.content
      },
      on: {
        "hook:mounted": function hookMounted($event) {
          return _vm.setData(field.slug);
        },
        "setIframelyData": function setIframelyData($event) {
          return _vm.setIframelyData($event, field.slug);
        }
      }
    })] : _c('vx-input', {
      staticClass: "py-0",
      attrs: {
        "type": field.type,
        "name": field.name,
        "label": field.name,
        "items": field.items,
        "multiple": field.multiple,
        "required": field.validation.required,
        "chips": ""
      },
      model: {
        value: _vm.collection.fields[field.slug],
        callback: function callback($$v) {
          _vm.$set(_vm.collection.fields, field.slug, $$v);
        },
        expression: "collection.fields[field.slug]"
      }
    })], 2);
  }), 1)], 1)], 1), _c('AddFile', {
    on: {
      "resolve": _vm.onResolveFile,
      "reject": function reject($event) {
        _vm.dialog.file = false;
      }
    },
    model: {
      value: _vm.dialog.file,
      callback: function callback($$v) {
        _vm.$set(_vm.dialog, "file", $$v);
      },
      expression: "dialog.file"
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }